import React from "react"

import Layout from "../../components/layout"
import { graphql, useStaticQuery } from "gatsby"
import SEO from "../../components/seo"

import MainBanner from "../../components/main-banner"
import ImageBanner from "../../components/image-banner"
import TriContent from "../../components/tri-content"
import ContactOptions from "../../components/contact-options"

import RetoZapopanLogo from "../../assets/logos/logo-reto-zapopan.svg"
import PlaceholderImage from "../../assets/placeholders/placeholder-01@2x.png"

const mainBannerContent = (
  <div>
    <img
      style={{ width: "10em", marginBottom: "2rem" }}
      src={RetoZapopanLogo}
      alt="Fonnor Logo"
    />
    <p>
      Diseñamos actividades de emprendimiento e innovación para niñas y niños en
      el programa de Reto Kids, jugando con 38 tripulantes en la 6ta y 8va
      Generación del programa.
    </p>
  </div>
)

const infoBannerContent = {
  left: (
    <div>
      <p>
        En Reto Kids se busca fomentar la cultura de innovación y emprendimiento
        en las niñas y niños de Zapopan mediante un programa con actividades que
        busquen desarrollar las habilidades de las niñas y los niños, quienes
        terminan desarrollando un prototipo de emprendimiento.
      </p>
      <p>
        En 2019, comenzamos colaborando en el primer Festival Creativo de Reto
        Kids, jugando con más de 60 niñas y niños.
      </p>
      <p>
        Posteriormente, seríamos parte de la 6ta Generación, jugando con 22
        niñas y niños la biblioteca pública de Las Águilas.
      </p>
    </div>
  ),
  right: (
    <div>
      <p>
        El programa culminó con la presentación de proyectos en KidZania
        (Guadalajara). Uno de los equipos de nuestra sede ganó 2do lugar con su
        proyecto de igualdad de género con Realidad Virtual (en cartón).
      </p>
      <p>
        En 2020 volvimos a ser parte del programa, ahora en la biblioteca
        pública de Santa Ana de Tepetitlán, jugando con 12 niños y niñas.
      </p>
      <p>
        Sin embargo, al cabo de 2 sesiones del programa, comenzó la cuarentena
        por el COVID-19. Con esto, migramos el programa a una versión digital en
        colaboración con las demás sedes del programa.
      </p>
    </div>
  ),
}

const infoBannerTitle = (
  <>
    <h1 className="w-bold">Inventando para nuestra Comunidad</h1>
    <h2 className="w-normal">Reto Kids, Gobierno de Zapopan</h2>
  </>
)

const RetoKidsPage = props => {
  const data = useStaticQuery(graphql`
    query RetoKidsQuery {
      retoKidsImg: file(relativePath: { eq: "meiquer-retokids-zapopan.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      retoKidsImg2: file(
        relativePath: { eq: "meiquer-retokids-zapopan-01.jpg" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout location={props.location}>
      <SEO title="Reto Kids" />
      <MainBanner
        image={data.retoKidsImg.childImageSharp.fluid}
        content={mainBannerContent}
      />
      <TriContent
        image={PlaceholderImage}
        content={infoBannerContent}
        title={infoBannerTitle}
      />
      <ImageBanner image={data.retoKidsImg2.childImageSharp.fluid} />
      <ContactOptions />
    </Layout>
  )
}

export default RetoKidsPage
